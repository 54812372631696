import React from "react";
import CountUp from 'react-countup';
import first from '../../Assets/Images/first.jpeg'

export default function Home() {
  return (
    <div>
      <section className="bg-white">
        <div className="lg:flex md:flex  lg:justify-around md:justify-around max-w-screen-xl mx-auto   ">
          <div className="lg:mt-32 md:mt-26 mt-12 text-center">
            <h1 className="ExpoM lg:text-start mb-4 text-6xl md:text-5xl xl:text-6xl">
              {" "}
              پەروەردە <span className="text-[#F79553]"> پاشان </span>{" "}
            </h1>
            <h1 className="ExpoM lg:text-start mb-4  text-6xl md:text-5xl xl:text-6xl ">
              {" "}
              فێرکردن{" "}
            </h1>
            <p className=" lg:text-start mx-auto w-96 Expo mb-6 text-gray-500 lg:mb-8 md:text-lg lg:text-xl">
              خولی نموونەیی زانست ئامانجی بەهێزکردنی خوێندکاران و ستافەکەیەتی بۆ
              دورخستنی توانا شاراوەکان و بەدەستهێنانی باشترینەکان.
            </p>
          </div>
          <div className="my-16">
            <img
              src={first}
              className="h-96"
              alt="mockup"
            />
          </div>
        </div>
        <div className="lg:flex  mx-auto  py-11 justify-center bg-gradient-to-r from-[#422DC9] to-[#742DC9]">
            <div className="text-white ExpoB md:mt-12 sm:mt-12 lg:mt-0">
                <CountUp className="text-7xl" end={100} duration={3} />

                <p className="text-xl">وانەبێژ</p>
            </div>
            <div className="lg:block hidden h-24 w-px mx-12 bg-gray-400 "></div>
            <div className="text-white ExpoB md:mt-12 sm:mt-12 lg:mt-0">
                <CountUp className="text-7xl" end={50} duration={3} />

                <p className="text-xl"> ئەندام </p>
            </div>
            <div className="lg:block hidden h-24 w-px mx-12 bg-gray-400 "></div>


            <div className="text-white ExpoB md:mt-12 sm:mt-12 lg:mt-0">
                <CountUp className="text-7xl" end={3000} duration={0.5} /><span className="text-7xl">h</span>
                <p className="text-xl"> خوێندن </p>
            </div>
            <div className="lg:block hidden h-24 w-px mx-12 bg-gray-400 "></div>


            <div className="text-white ExpoB md:mt-12 sm:mt-12 lg:mt-0">
            <span className="text-7xl">+</span> <CountUp className="text-7xl" end={9000} duration={0.5} />

                <p className="text-xl"> خوێندکار </p>
            </div>



        </div>
      </section>
    </div>
  );
}
