import React from 'react'
import ZEC from '../Assets/Logo/xuli-zanst-logo.png'

export default function Footer() {
  return (
    <div className=' bg-[#14141F] p-24 text-white'>
        <footer className='lg:flex md:flex xl:flex justify-around '>
            <div className='my-6'>
                <img src={ZEC} alt="Zanst Examplary Course" className=''/>
            </div>
            <div>
                <div className=' ExpoM text-start  my-6'>
                    <h1 className='text-white mb-2'>وانەکان</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>کیمیا</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>زیندەزانی</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>بیرکاری</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>فیزیا</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>ئینگلیزی</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>عربی</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>کوردی</h1>
                </div>
            </div>
            <div>
            <div className=' ExpoM text-start my-6 '>
                    <h1 className='text-white mb-2'>بەشەکان</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>سەرەکی</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>چالاکی</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>پۆدکاست</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>دەربارە</h1>
                    <h1 className='text-[#8A8A8F] mb-2'>پەیوەندی</h1>
                </div>
            </div>
            <div className='Expo text-start my-6 '>
                <h1 className='text-white mb-2'>خولی نموونەیی زانست </h1>
                <h1 className='lg:w-96 xl:w-96  text-[#8A8A8F]'>خولی نمونەیی زانست بە سێ ئامانجی سەرەکی لەدایک بوو ئەوانیش چاندنی کلتوری خۆبەخشی، پاڵپشتی و ڕێنمایی فێرخوازان بۆ گەیشتن بە ئامانجەکانیان، و بەهێزکردنی ستافەکەمان بۆ ئەوەی هاوڵاتییەکی بەرپرسیار بن. ئەمانەش بە دروستکردنی ژینگەیەکی هاوبەش و گشتگیر و سەلامەت، خوێندکارەکانمان فێر دەکرێن و هاندەدرێن بۆ تێگەیشتن لە بەهای پەروەردە؛ ستافەکەمان دەسەڵاتیان پێدراوە بۆ ئەوەی تاکێکی باشتر بن؛ ئەم خزمەتگوزاریانە بە خۆڕایی بە ڕۆحی خۆبەخشی پێشکەش دەکرێن.</h1>
            </div>
        </footer>
        </div>
  )
}
