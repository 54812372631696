import './App.css';

import { useTranslation } from 'react-i18next';
import './i18next';
import Home from './Pages/Home/Home'

function App() {

  const { t, i18n } = useTranslation();



  return (
    <div className="App">
      <Home t={t} i18n={i18n} />

    </div>
  );
}

export default App;
