import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './locales/en/ENtranslation.json';
import ku from './locales/ku/KUtranslation.json'

i18next.use(initReactI18next).init({
  resources: {
    English: {
      translation: en,
    },
    Kurdish: {
      translation: ku,
    },
  },
  lng: localStorage.getItem('lng') || 'English',
});

export default i18next;