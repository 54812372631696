import React from "react";
import Navbar from '../../Components/Navbar'
import Hero from '../Hero/Hero'
import WhyUs from '../WhyUs/WhyUs'
import Lessness from '../Lessness/Lessness'
import Feedback from '../Feedback/Feedback'
// import Activity from '../Activity/Activity'
// import Teams from '../Teams/Teams'
import Footer from '../../Components/Footer'

export default function Home({t,i18n}) {
  return (
    <div>
       <Navbar t={t} i18n={i18n}/>
      <Hero/>
      <WhyUs/>
      <Feedback/>
      <Lessness/>
      {/* <Activity/>
      <Teams/> */}
      <Footer/>
    </div>
  );
}
