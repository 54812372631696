import React,{useState,useEffect} from "react";
import Logo from '../Assets/Logo/xuli-zanst-logo.png'
import english from '../Assets/Images/english.png'
import kurdish from '../Assets/Images/kurdish.png'
import down from '../Assets/Images/caret-down.svg'

export default function Navbar({t,i18n}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en"); // English's language code is typically 'en'.

  const languages = [
    { name: "Kurdish", icon: kurdish },
    { name: "English", icon: english },
  ];

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
    setIsOpen(!isOpen);
  };

  const handleChangeLng = (lngCode) => {
    i18n.changeLanguage(lngCode);
    localStorage.setItem("lng", lngCode);

    if (lngCode === "Kurdish") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  };

useEffect(() => {
  // Check the language in local storage on component mount
  const storedLanguage = localStorage.getItem("lng");

  if (storedLanguage) {
    setSelectedLanguage(storedLanguage);

    if (storedLanguage === "Kurdish") {
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.setAttribute("dir", "ltr");
    }
  }
}, [selectedLanguage]);

  return (
    <nav className="bg-white border-gray-200">
      <div className="Expo container max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-6">
        <a 
         href="https://xulizanst.com"
         className="flex items-center">
          <img
            src={Logo}
            className="h-16 mr-3"
            alt="Bright Institute Logo"
          />
          <span  className="lg:block md:block hidden Expo mr-6 self-center text-2xl font-semibold whitespace-nowrap">
         خولی نموونەیی زانست 
          </span>
        </a>
        <button
        data-collapse-toggle="navbar-multi-level"
        type="button"
        className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
        aria-controls="navbar-multi-level"
        aria-expanded={isDropdownOpen ? "true" : "false"}
        onClick={toggleDropdown}
      >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isDropdownOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-multi-level"
        >
          <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white ">
            <li>
              <a
                href="https://xulizanst.com"
                className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent "
                aria-current="page"
              >
                سەرەکی
              </a>
            </li>
            <li>
              <a
                href="https://xulizanst.com"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
              >
                هەواڵ
              </a>
            </li>
            <li>
              <a
                href="https://xulizanst.com"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 "
              >
                چالاکی
              </a>
            </li>
            <li>
              <a
                href="https://xulizanst.com"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
              >
                دەربارە
              </a>
            </li>
            <li>
              <a
                href="https://xulizanst.com"
                className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0"
              >
                پەیوەندی
              </a>
            </li>
            <li>
            <button onClick={() => setIsOpen(!isOpen)} className="  rounded flex align-middle justify-center">
                {selectedLanguage}
                <span className="mt-1 mx-2"><img src={down} alt="Drop Down" /></span>
              </button>

              {isOpen && (
                <ul className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                  {languages.map((lang) => (
                    <li key={lang.name}>

                      <button
                       onClick={() => {
                          setSelectedLanguage(lang.name);
                          handleChangeLng(lang.name);
                          toggleDropdown();
                        }}
                        className="flex align-middle px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                      >
                        <img
                          src={lang.icon}
                          alt={lang.name}
                          className="w-6 mx-2"
                        />

                        {lang.name}
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            
          </ul>
        </div>
      </div>
    </nav>
  );
}
