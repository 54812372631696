import React from "react";
import feedback from "../../Assets/Images/feedback.png";
import feedback2 from "../../Assets/Images/feedback(2).png";

export default function Feedback() {
  return (
    <div>
      <section class="bg-white">
        <div class=" flex flex-col lg:flex-row md:flex-row justify-around max-w-screen-xl mx-auto p-4">
          <div class="mt-8 md:mt-32">
            <div className="lg:block md:block hidden">
              <h1 className="w-full ExpoM text-start mb-4 text-3xl md:text-5xl xl:text-6xl">
                فیدباکی <span className="text-[#F79553]"> خوێندکاران </span>
              </h1>
              <h1 class="ExpoM text-start mb-4 text-3xl md:text-5xl xl:text-6xl">
                بۆ خول
              </h1>
            </div>
            <div className="block lg:hidden md:hi">
              <h1 className="w-full ExpoM text-start mb-4 text-3xl md:text-5xl xl:text-6xl">
                فیدباکی <span className="text-[#F79553]"> خوێندکاران </span>
              </h1>
            </div>

            <p class="text-start w-full md:w-96 Expo mb-6 text-gray-500 lg:mb-8 text-base md:text-lg lg:text-xl">
              خولی نموونەیی زانست ئامانجی بەهێزکردنی خوێندکاران و ستافەکەیەتی بۆ
              دورخستنی توانا شاراوەکان و بەدەستهێنانی باشترینەکان.
            </p>
          </div>
          <div className="hidden lg:flex flex-wrap mt-8 md:mt-16 مخ">
            <div class="p-4" dir="rtl">
              <div class="relative w-full sm:w-[320px] md:w-[640px] h-[180px] sm:h-[360px] rounded-2xl bg-white overflow-hidden">
                {/* <!-- Top-left circle --> */}
                <div
                  aria-hidden="true"
                  class="absolute -top-16 -rigth-12 w-[200px] h-[200px] bg-[#422DC9] opacity-25 rounded-full grid place-items-center"
                >
                  <svg
                    class="w-[64px] h-[72px] ml-3 mt-3"
                    viewBox="0 0 64 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M60 29.0718C65.3333 32.151 65.3333 39.849 60 42.9282L12 70.641C6.66668 73.7202 1.16801e-05 69.8712 1.19493e-05 63.7128L1.4372e-05 8.28719C1.46412e-05 2.12878 6.66668 -1.72022 12 1.35899L60 29.0718Z"
                      fill="white"
                    />
                  </svg>
                </div>
                {/* <!-- Right big circle --> */}
                <div
                  aria-hidden="true"
                  class="absolute w-[512px] h-[512px]  bg-[#422DC9] opacity-25 rounded-full -top-20 -left-56"
                ></div>
                {/* <!-- Images --> */}
                <div class="absolute top-10 left-8">
                  <img
                    src={feedback}
                    alt="Screenshot of Tailwind CSS code"
                    class="w-64 h-36 object-cover rounded-2xl shadow-2xl -rotate-3 translate-x-16 -translate-y-2"
                  />
                  <img
                    src={feedback2}
                    alt="YouTuber cheerfully giving a shaka"
                    class="w-64 h-36 object-cover rounded-2xl shadow-2xl rotate-3"
                  />
                </div>
                {/* <!-- Play button --> */}
                <div class="absolute left-12 inset-y-0 grid items-center">
                  <a
                    href="#d"
                    class="w-12 h-12 bg-[#742DC9] rounded-full ring-4 ring-white grid place-items-center hover:bg-[#422DC9] transition"
                  >
                    <span class="sr-only">Watch the video</span>
                    <svg
                      class="ml-1 w-4"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
                {/* <!-- Content --> */}
                <div class="relative p-8 flex flex-col justify-end h-full space-y-4 w-2/3">
                  <p class="text-start font-semibold text-xs text-[#742DC9] tracking-wider Expo">
                    {" "}
                    لە یوتیوبەوە تەماشا بکە
                  </p>
                  <div className="hidden lg:block md:block">
                    <h1 class="text-4xl text-start ExpoM text-gray-900 w-80">
                      {" "}
                      خوێندکاران چی دەڵێن؟
                    </h1>
                  </div>
                  <div className="mt-14">
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="relative lg:hidden md:hidden mx-auto">
            <div>
              <div class="absolute z-20 mx-auto top-16 left-28 items-center">
                <a
                  href="#d"
                  class="w-12 h-12 bg-[#742DC9] rounded-full ring-4 ring-white grid place-items-center hover:bg-[#422DC9] transition"
                >
                  <span class="sr-only">Watch the video</span>
                  <svg
                    class="ml-1 w-4"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <img
                src={feedback}
                alt="Screenshot of Tailwind CSS code"
                class="w-64 h-36 object-cover rounded-2xl shadow-2xl -rotate-0 translate-x-1 my-6 -translate-y-2"
              />
            </div>

            <div>
              <div class="absolute z-20 mx-auto bottom-12 left-28 items-center">
                <a
                  href="#d"
                  class="w-12 h-12 bg-[#742DC9] rounded-full ring-4 ring-white grid place-items-center hover:bg-[#422DC9] transition"
                >
                  <span class="sr-only">Watch the video</span>
                  <svg
                    class="ml-1 w-4"
                    viewBox="0 0 16 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                      fill="white"
                    />
                  </svg>
                </a>
              </div>
              <img
                src={feedback2}
                alt="YouTuber cheerfully giving a shaka"
                class="w-64 h-36 object-cover rounded-2xl shadow-2xl rotate-0"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
